@import "_custom-properties.css";
/*---------------------------------------------------------------------
                          Dummy data
-----------------------------------------------------------------------*/

/*WordPress Core*/
.alignnone {
    margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
    display: block;
    margin: 5px auto 15px auto;
}

.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

a img.alignnone {
    margin: 5px 20px 20px 0;
}

a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.wp-caption {
    background: var(--white-color);
    border: 1px solid var(--old-border-color);
    color: var(--title-color);
    clear: both;
    max-width: 100%;
    padding: 5px;
    text-align: center;
}

.wp-caption.alignnone {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
    margin: 5px 0 20px 20px;
}

.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
}

.wp-caption p.wp-caption-text {
    font-size: 11px;
    line-height: 17px;
    margin: 0;
    padding: 0 4px 5px;
}

.wp-block-audio figcaption,
.wp-block-video figcaption,
.wp-block-image figcaption,
.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption {
    font-size: 12px;
    line-height: 1.6;
    margin: 0;
    padding: 0.5rem;
    text-align: center;
}

.wp-block-button {
    margin-bottom: 30px;
}

.wp-block-button__link,
.wp-block-file a.wp-block-file__button {
    color: var(--white-color);
    background: var(--secondary-color);
}

.wp-block-button__link:hover,
.wp-block-file a.wp-block-file__button:hover {
    background: var(--primary-color);
}

.is-style-outline .wp-block-button__link {
    border-color: var(--secondary-color);
    color: var(--secondary-color);
}

.is-style-outline .wp-block-button__link:hover {
    background: transparent;
    border-color: var(--primary-color);
    color: var(--primary-color) !important;
}

.wp-block-cover {
    margin-bottom: 1.875em;
}

.wp-block-cover.has-background-dim {
    color: var(--white-color);
}

.wp-block-search .wp-block-search__inside-wrapper {
    position: relative;
}

.wp-block-search .wp-block-search__input {
    width: auto;
}

/* Text meant only for screen readers */
.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    height: 0;
    overflow: hidden;
    position: absolute !important;
    white-space: nowrap;
    width: 1px;
}

.screen-reader-text:focus {
    background-color: var(--old-border-color);
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    color: #21759b;
    display: block;
    font-size: 0.875rem;
    font-weight: bold;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
}

/*Blog*/

.type-service .css_prefix-blog-box .css_prefix-blog-image {
    float: none;
    text-align: left;
    padding: 30px 30px 0;
}

.type-service .css_prefix-blog-box .css_prefix-blog-image img {
    background: transparent;
}

/*Sticky Post*/
.sticky .iq-blog-box, .sticky .css_prefix-blog-box {
    background: var(--footer-bg);
}

.sticky .css_prefix-blog-box .css_prefix-blogtag li a,
.sticky .css_prefix-blog-box .iq-blogtag li a,
.sticky .iq-blog-box .iq-blogtag li a {
    background: var(--white-color);
}

.sticky .css_prefix-blog-box .css_prefix-blogtag li a:hover,
.sticky .css_prefix-blog-box .iq-blogtag li a:hover,
.sticky .iq-blog-box .iq-blogtag li a:hover {
    background: var(--primary-color);
}

/*latest Blog*/

.iq-blog-box, .css_prefix-blog-box {
    background: var(--white-color);
    border: 1px solid var(--footer-bg);
    border-radius: 5px;
    margin-bottom: 45px;
    overflow: hidden;
    position: relative;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.iq-blog-box .iq-blog-category,
.css_prefix-blog-box .iq-blog-category {
    padding: 0;
    list-style: none;
    margin-bottom: 5px;
}

.iq-blog-box .iq-blog-category li,
.css_prefix-blog-box .iq-blog-category li {
    display: inline-block;
    margin-right: 11px;
    margin-bottom: 15px;
}

.iq-blog-box .iq-blog-category li:last-child,
.css_prefix-blog-box .iq-blog-category li:last-child {
    margin-right: 0;
}

.iq-blog-box .iq-blog-category li a,
.css_prefix-blog-box .iq-blog-category li a {
    display: inline-block;
    padding: 8px 15px;
    background: var(--primary-color);
    color: var(--white-color);
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-bold);
    letter-spacing: var(--letter-spacing-two);
    text-transform: uppercase;
    border-radius: var(--border-radius-box);
    -webkit-border-radius: var(--border-radius-box);
}

.iq-blog-box .iq-blog-category li a:hover,
.css_prefix-blog-box .iq-blog-category li a:hover {
    background: var(--secondary-color);
}

.iq-blog-box:hover {
    -moz-box-shadow: var(--global-box-shadow);
    -webkit-box-shadow: var(--global-box-shadow);
    box-shadow: var(--global-box-shadow);
}

.single-post .hentry .iq-blog-box:hover,
.single .hentry .iq-blog-box:hover, .single-post .hentry .css_prefix-blog-box:hover,
.single .hentry .css_prefix-blog-box:hover {
    box-shadow: none;
}

.iq-blog-box .iq-blog-image, .css_prefix-blog-box .css_prefix-blog-image {
    display: inline-block;
    float: left;
    position: relative;
    text-align: center;
    width: 100%;
}

.iq-blog-box .iq-blog-image img, .css_prefix-blog-box .css_prefix-blog-image img {
    background: var(--global-body-bgcolor);
    margin-bottom: 30px;
}

.blog-date {
    background: var(--white-color);
    border-radius: 90px;
    color: var(--primary-color);
    left: 15px;
    padding: 5px 10px;
    position: absolute;
    text-align: center;
    top: 15px;
}

.blog-date .day {
    color: var(--primary-color);
    font-weight: bold;
    line-height: normal;
}

.blog-date .month {
    float: left;
    line-height: normal;
}

.iq-blog-box .blog-title, .css_prefix-blog-box .blog-title {
    margin: 0 0 5px 0;
}

.iq-blog-box .blog-title a h4, .css-prefix-blog-box .blog-title a h4 {
    text-decoration: none;
}

.iq-blog-box .blog-title a:hover,
.iq-blog-box .blog-title a:hover h4,
.css_prefix-blog-box .blog-title a:hover,
.css_prefix-blog-box .blog-title a:hover h4 {
    color: var(--primary-color);
    text-decoration: none;
}

.iq-blog-box .iq-blog-detail,
.css_prefix-blog-box .css_prefix-blog-detail {
    display: inline-block;
    float: left;
    padding: 30px;
    width: 100%;
}

.single-post .css_prefix-blog-box,
.single-post .iq-blog-box {
    padding: 0;
    border: none;
    background: transparent;
    margin-bottom: 70px;
}

.single-post .css_prefix-blog-box .css_prefix-blog-detail,
.single-post .css_prefix-blog-box .css_prefix-blog-detail,
.single-post .iq-blog-box .css_prefix-blog-detail,
.single-post .has-post-thumbnail .css_prefix-blog-box .css_prefix-blog-detail {
    padding: 0 0 70px;
    background: transparent;
    margin-bottom: 70px;
    border-bottom: 1px solid var(--border-color-light);
}

.iq-blog-box .iq-blog-meta, .css_prefix-blog-box .css_prefix-blog-meta {
    margin: 0 0 10px;
    padding: 0;
}

.iq-blog-box .iq-blog-meta li,
.css_prefix-blog-box .css_prefix-blog-meta li {
    display: inline-block;
    position: relative;
    padding-right: 20px;
    margin-right: 10px;
}

.iq-blog-box .iq-blog-meta li:last-child,
.css_prefix-blog-box .css_prefix-blog-meta li:last-child {
    margin-right: 0;
    padding-right: 0;
}

.iq-blog-box .iq-blog-meta ul li a, .css_prefix-blog-box .css_prefix-blog-meta ul li a {
    color: var(--tertiry-color);
    text-decoration: none;
}

.iq-blog-box .iq-blog-meta ul li a:hover, .css_prefix-blog-box .css_prefix-blog-meta ul li a:hover {
    color: var(--primary-color);
    text-decoration: none;
}

.iq-blog-box .iq-blog-meta ul li a, .css_prefix-blog-box .css_prefix-blog-meta ul li a {
    text-decoration: none;
}

.iq-blog-box .iq-blog-meta li:last-child::after,
.css_prefix-blog-box .css_prefix-blog-meta li:last-child::after {
    content: none;
}

.iq-blog-box .iq-blog-meta li:after,
.css_prefix-blog-box .css_prefix-blog-meta li:after {
    content: "/";
    position: absolute;
    top: 50%;
    right: 0;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.iq-blog-box .iq-blog-meta ul .posted-by a,
.css_prefix-blog-box .css_prefix-blog-meta ul .posted-by a {
    font-weight: var(--font-weight-semi-bold);
    letter-spacing: var(--letter-spacing-one);
    text-transform: capitalize;
    color: var(--primary-color);
}

.iq-blog-box .iq-blog-meta .posted-by a:hover,
.css_prefix-blog-box .css_prefix-blog-meta .posted-by a:hover {
    color: var(--secondary-color);
}

.iq-blog-box .iq-blog-meta ul .iq-postdate,
.css_prefix-blog-box .css_prefix-blog-meta ul .iq-postdate {
    padding: 0;
}

.iq-blog-box .iq-blog-meta ul .iq-postdate a,
.css_prefix-blog-box .css_prefix-blog-meta ul .iq-postdate a {
    font-weight: var(--font-weight-medium);
}

.iq-blog-box .iq-blog-meta ul li a i,
.iq-blog-box .iq-blog-meta ul li i, .css_prefix-blog-box .css_prefix-blog-meta ul li a i,
.css_prefix-blog-box .css_prefix-blog-meta ul li i {
    color: var(--primary-color);
}

.qloud-space-bottom .iq-blog-box .iq-blog-detail, .qloud-space-bottom .css_prefix-blog-box .css_prefix-blog-detail {
    padding: 0 15px 15px;
}

.owl-carousel.blog-carousel .owl-stage-outer {
    padding: 0;
}

.owl-carousel.blog-carousel {
    clear: both;
    display: block;
    float: left;
    margin: 0;
    width: 100%;
}

.iq-blog-box .iq-blog-meta ul, .css_prefix-blog-box .css_prefix-meta ul {
    font-size: 14px;
    margin-bottom: 0;
    text-align: left;
}

.sticky .iq-blog-box .iq-blog-meta ul, .sticky .css_prefix-blog-box .css_prefix-meta ul {
    border-color: var(--white-color);
}

.iq-blog-box .iq-blog-detail p, .css_prefix-blog-box .css_prefix-blog-detail p {
    margin-bottom: 15px;
    margin-top: 0;
}

.iq-blog-box .iq-blogtag, .css_prefix-blog-box .css_prefix-blog-image .iq-blogtag {
    bottom: 15px;
    display: inline-block;
    left: 20px;
    margin: 0 0 0 0;
    padding: 0;
    position: absolute;
    width: 100%;
}

.single-post .css_prefix .css_prefix-blog-box .blog-footer,
.single-post .css_prefix .iq-blog-box .blog-footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    float: left;
}

.css_prefix-blog-box .css_prefix-blogtag,
.css_prefix-blog-box .iq-blogtag,
.iq-blog-box .iq-blogtag {
    margin-bottom: 0;
}

.iq-blog-box .iq-blogtag li,
.css_prefix-blog-box .css_prefix-blogtag li,
.css_prefix-blog-box .iq-blogtag li {
    display: inline-block;
    list-style: none;
    margin-right: 15px;
    margin-bottom: 15px;
}

.single-post .css_prefix-blog-box .css_prefix-blogtag li.css_prefix-label,
.single-post .css_prefix-blog-box .iq-blogtag li.css_prefix-label,
.single-post .iq-blog-box .iq-blogtag li.css_prefix-label {
    display: block;
}

.css_prefix-blog-box .css_prefix-blogtag li.css_prefix-label,
.css_prefix-blog-box .iq-blogtag li.css_prefix-label,
.iq-blog-box .iq-blogtag li.css_prefix-label {
    font-family: var(--highlight-font-family);
    font-weight: var(--font-weight-bold);
    color: var(--title-color);
}

.iq-blog-box .iq-blogtag li:last-child,
.css_prefix-blog-box .css_prefix-blogtag li:last-child,
.css_prefix-blog-box .iq-blogtag li:last-child {
    margin-right: 0;
}

.iq-blog-box .iq-blogtag li a,
.css_prefix-blog-box .css_prefix-blogtag li a,
.css_prefix-blog-box .iq-blogtag li a {
    background: var(--global-body-bgcolor);
    display: inline-block;
    color: var(--tertiry-color);
    text-transform: uppercase;
    font-size: var(--font-size-xs) !important;
    font-weight: var(--font-weight-medium);
    letter-spacing: var(--letter-spacing-one);
    padding: 5px 15px;
    border-radius: 5px;
    text-decoration: none;
}

.iq-blog-box .iq-blogtag li a:hover,
.css_prefix-blog-box .css_prefix-blogtag li a:hover,
.css_prefix-blog-box .iq-blogtag li a:hover {
    background: var(--primary-color);
    color: var(--white-color);
    text-decoration: none;
}

.iq-fancy-box-list-1.iq-shadow:hover,
.iq-fancy-box-list-1.iq-shadow.active {
    -moz-box-shadow: 4.871px 19.659px 30px 0 rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 4.871px 19.659px 30px 0 rgb(0 0 0 / 6%);
    box-shadow: 4.871px 19.659px 30px 0 rgb(0 0 0 / 6%);
}

.iq-blog-box .iq-blogtag li,
.css_prefix-blog-box .css_prefix-blogtag li,
.css_prefix-blog-box .iq-blogtag li {
    -moz-transition: all 0.5s ease-out 0s;
    -ms-transition: all 0.5s ease-out 0s;
    -o-transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
    transition: all 0.5s ease-out 0s;
}

.format-audio.has-post-thumbnail .css_prefix-blog-box .css_prefix-blog-detail,
.format-gallery.has-post-thumbnail .css_prefix-blog-box .css_prefix-blog-detail,
.format-video.has-post-thumbnail .css_prefix-blog-box .css_prefix-blog-detail,
.format-link.has-post-thumbnail .css_prefix-blog-box .css_prefix-blog-detail,
.format-quote.has-post-thumbnail .css_prefix-blog-box .css_prefix-blog-detail {
    padding: 30px;
}

.single-post .format-audio.has-post-thumbnail .css_prefix-blog-box .css_prefix-blog-detail,
.single-post .format-gallery.has-post-thumbnail .css_prefix-blog-box .css_prefix-blog-detail,
.single-post .format-video.has-post-thumbnail .css_prefix-blog-box .css_prefix-blog-detail,
.single-post .format-link.has-post-thumbnail .css_prefix-blog-box .css_prefix-blog-detail,
.single-post .format-quote.has-post-thumbnail .css_prefix-blog-box .css_prefix-blog-detail {
    padding: 0 0 70px;
}

.has-post-thumbnail .iq-blog-box .iq-blog-detail, .has-post-thumbnail .css_prefix-blog-box .css_prefix-blog-detail {
    display: inline-block;
    float: left;
    padding: 0 30px 30px;
    width: 100%;
}

.has-post-thumbnail .iq-blog-box .iq-blogtag, .has-post-thumbnail .css_prefix-blog-box .iq-blogtag {
    margin: 0 0 0 30px;
}

.iq-blog-box .iq-blog-detail blockquote p, .css_prefix-blog-box .css_prefix-blog-detail blockquote p {
    margin-bottom: 0;
}

.iq-blog-box .iq-blog-detail .blockquote, .css_prefix-blog-box .css_prefix-blog-detail .blockquote {
    margin-top: 0;
}

.blog-content .wp-audio-shortcode {
    margin-bottom: 15px;
}

.post-password-form input {
    float: none;
}

embed,
iframe,
object {
    max-width: 100%;
    width: 100%;
}

.blog .pagination {
    margin-top: 30px;
}

.iq-blog-detail .blog-content .wp-block-archives,
.iq-blog-detail .blog-content .wp-block-categories,
.iq-blog-detail .blog-content .wp-block-latest-posts,
.css_prefix-blog-detail .blog-content .wp-block-archives,
.css_prefix-blog-detail .blog-content .wp-block-categories,
.css_prefix-blog-detail .blog-content .wp-block-latest-posts {
    margin: 0 0 30px;
    padding: 0;
}

.iq-blog-detail .blog-content .wp-block-archives li,
.iq-blog-detail .blog-content .wp-block-categories li,
.iq-blog-detail .blog-content .wp-block-latest-posts li .css_prefix-blog-detail .blog-content .wp-block-archives li,
.css_prefix-blog-detail .blog-content .wp-block-categories li,
.css_prefix-blog-detail .blog-content .wp-block-latest-posts li {
    list-style-type: none;
}

.blog-content .elementor-column-gap-default>.elementor-row>.elementor-column>.elementor-element-populated {
    padding: 0;
}

.has-dates .wp-block-latest-comments__comment,
.has-excerpts .wp-block-latest-comments__comment,
.wp-block-latest-comments__comment {
    display: inline-block;
    width: 100%;
}

.wp-block-table.is-style-stripes td {
    border-color: #eaeaea;
}

.wp-block-table td,
.wp-block-table th {
    text-align: left;
}

.wp-block-latest-posts.is-grid.has-dates {
    margin: 0;
}

.blog-content {
    display: inline-block;
    width: 100%;
}

.css_prefix-blog-box .css_prefix-blog-detail .blog-button {
    margin-top: 0em;
}

/* Blog Page Link */
.page-links {
    clear: both;
    margin: 15px 0 10px;
    text-transform: uppercase;
}

.iq-blog-detail .blog-content .page-links a,
.page-links>span.page-number,
.page-links a, .css_prefix-blog-detail .blog-content .page-links a,
.page-links>span.page-number {
    background: var(--old-border-color);
    border: none;
    color: var(--title-color);
    display: inline-block;
    height: 30px;
    line-height: 30px;
    margin-left: 5px;
    padding: 0;
    text-align: center;
    width: 30px;
}

.iq-blog-detail .blog-content .page-links a:hover, .css_prefix-blog-detail .blog-content .page-links a:hover {
    background: var(--primary-color);
    border-color: transparent;
    color: var(--white-color);
    text-decoration: none;
}

.iq-blog-detail .blog-content .page-links>span.page-number,
.page-links>span.page-number, .css_prefix-blog-detail .blog-content .page-links>span.page-number {
    background: var(--primary-color);
    color: var(--white-color);
}

article.hentry .iq-blog-detail .blog-content .page-links a:hover, article.hentry .css_prefix-blog-detail .blog-content .page-links a:hover {
    color: var(--white-color);
}

/* Gallery */
.gallery-size-thumbnail .gallery-item {
    display: inline-block;
    margin-bottom: 2%;
    margin-right: 2%;
    vertical-align: top;
    width: 18.4%;
}

.gallery-item .gallery-caption {
    font-size: 14px;
    line-height: 22px;
}

.gallery-size-thumbnail .gallery-item img {
    margin-bottom: 10px;
}

.gallery-columns-1 .gallery-item {
    margin-right: 0;
    width: 100%;
}

.gallery-columns-2 .gallery-item {
    width: 48%;
}

.gallery-columns-3 .gallery-item {
    width: 31.3%;
}

.gallery-columns-4 .gallery-item {
    width: 23%;
}

.gallery-columns-5 .gallery-item {
    width: 18%;
}

.gallery-columns-6 .gallery-item {
    width: 14.6%;
}

.gallery-columns-7 .gallery-item {
    width: 12.2%;
}

.gallery-columns-8 .gallery-item {
    width: 10.5%;
}

.gallery-columns-9 .gallery-item {
    width: 9.1%;
}

.gallery.gallery-size-thumbnail {
    display: inline-block;
    width: 100%;
}

/* Audio */
article.hentry.format-audio .iq-blog-image {
    text-align: left;
}

article.hentry.format-audio .iq-blog-image p:nth-child(-n+2) {
    display: inline-block;
    margin-bottom: 0;
    padding-top: 30px;
}

article.hentry.format-audio .iq-blog-image p {
    margin-bottom: 0;
    padding-left: 30px;
}

article.hentry.format-video .iq-blog-image p {
    margin-bottom: 0;
}

/*pagination-nav*/
.page-numbers {
    display: -ms-flexbox;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.page-numbers li .page-numbers {
    background-color: var(--white-color);
    border: 1px solid var(--old-border-color);
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    color: var(--title-color);
    display: block;
    height: 45px;
    line-height: 45px;
    margin: 0 5px;
    padding: 0 15px;
    position: relative;
    text-align: center;
    width: 45px;
}

.page-numbers li .page-numbers:hover {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--white-color);
    text-decoration: none;
    z-index: 2;
}

.page-numbers li .page-numbers:focus {
    box-shadow: none;
    outline: 0;
    z-index: 2;
}

.page-numbers li .page-numbers:not(:disabled):not(.disabled) {
    cursor: pointer
}

.page-numbers li .page-numbers.current {
    background: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--white-color);
    -moz-transition: all 0.5s ease-out 0s;
    -ms-transition: all 0.5s ease-out 0s;
    -o-transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
    transition: all 0.5s ease-out 0s;
    z-index: 1;
}

.page-numbers li .next.page-numbers,
.page-numbers li .prev.page-numbers {
    width: auto;
}

.assistive-text,
.site .screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    display: none;
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
}

.main-navigation .assistive-text:focus,
.site .screen-reader-text:hover,
.site .screen-reader-text:active,
.site .screen-reader-text:focus {
    background: var(--white-color);
    border: 2px solid #333;
    border-radius: 3px;
    clip: auto !important;
    color: var(--title-color);
    display: block;
    font-size: 12px;
    height: auto;
    left: 5px;
    padding: 12px;
    position: absolute;
    top: 5px;
    width: auto;
    z-index: 100000;
    /* Above WP toolbar */
}



.wp-block-button__link {
    font-size: 16px;
    padding: 10px 30px;
}

/* footer */
footer .widget ul li {
    border: none;
    padding: 0.5em 0;
    margin-bottom: 0;
    list-style: none;
}

.css_prefix-blog-box .entry-title:hover {
    color: var(--primary-color);
}

.wp-block-button.is-style-squared .wp-block-button__link {
    border-radius: 0;
}

.wp-container-1.wp-block-group.has-background {
    padding: 1.25em 1.875em;
    margin-bottom: 1em;
    color: var(--global-white-light-color);
}


.css_prefix-blog-detail .blog-content .wp-block-archives li,
.widget_archive ul li {
    margin-bottom: 1em;
    border-bottom: 0.063em solid var(--border-color-light);
    padding-bottom: 1em;
    list-style: none;
}

/*==================
responsive
========================*/
@media (max-width: 767px) {

    .single-post .css_prefix .css_prefix-blog-box .blog-footer,
    .single-post .css_prefix .iq-blog-box .blog-footer {
        display: block;
        float: none;
    }

    .single-post .css_prefix .css_prefix-blog-box .css_prefix-blog-detail,
    .single-post .css_prefix .iq-blog-box .css_prefix-blog-detail {
        padding: 0 0 35px;
        margin-bottom: 50px;
    }

    .blog-footer .iq-author-social {
        margin-top: 15px;
    }
}